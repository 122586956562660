<template>
    <div>
            <b-row>
                <b-col cols="12">
                    <b-form-group :label="$t('name')">
                        <b-input-group>
                            <div class="label-as-input">{{ isNotNullValue(form.name) }}</div>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group :label="$t('code')">
                        <b-input-group>
                            <div class="label-as-input">{{ isNotNullValue(form.code) }}</div>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
    </div>
</template>

<script>
    //Service
    import CityService from "@/services/CityService";

    export default {
        props: {
            formId: {
                type: Number,
            }
        },
        data() {
            return {
                form: {
                    name: null,
                    code: null,
                }
            }
        },
        async created() {
            await this.get(this.formId)
        },
        methods: {
            get(id) {
                CityService.get(id)
                           .then((response) => {
                               this.form = response.data.data;
                           })
                           .catch((error) => {
                               this.showErrors(error)
                           });
            }
        }
    }
</script>
